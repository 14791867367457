.contact-heading{
    margin-top: 5rem;
    text-align: center;
    padding: 5%;
    background-color: gainsboro;
}



.formDivContainer{
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    height: 100%; /* Adjust the height as needed */
}

.formDiv{
    width: 100%;
    max-width: 600px; /* This can be the maximum width for your form */
    margin: 4rem 0;
}

@media only screen and (max-width: 600px) {
    .formDivContainer{
        padding: 3rem;
    }
  }