.trailer-heading{
    margin-top: 10%;
}

.trailer-heading h1{
    text-align: center;
}

.cards-home a{
    text-decoration: none;
}

@media only screen and (max-width: 600px) {

    .cards-trailers{
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .price_card:not(:last-child){
        margin-right:0;
    }

    .trailer-heading {
        margin-top: 35%;
    }
  
  }