@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


body {
  line-height: 1.5;
}

.cards-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  background-color: gainsboro;
  padding: 3rem;
}

.home-heading {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: center;
  margin-left: 6rem;

}

.home-heading h1 {
  color: orange;
}

.home-heading button {
  padding: 10px;
  border-radius: 30px 30px;
  background-color: white;
  width: 150px;
  margin-top: 10px;
}

button.buttonsOne {
  background-color: black;
  color: white;
  border: 0;
}

.buttons {
  display: flex;
  gap: 1rem;
}

.home-heading h1 {

  inline-size: 400px;
  overflow-wrap: break-word;
}

.imageDiv img {
  clip-path: ellipse(77% 49% at 9% 50%);
}

.home-heading p {
  color: black;
  inline-size: 400px;
  overflow-wrap: break-word;
}

.headerDiv {
  display: grid;
  grid-template-columns: 1fr .5fr;
  max-width: 1000px;
  margin: 6rem auto;
}



.essentialHeading {
  background-color: black;
  padding: 9rem 6rem;
  display: flex;
  align-items: center;
  gap: 3rem;
  color: white;
  height: 700px;
}

.essentialDescription {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  color: white;

}

.essentialSpan {
  color: red;
}







.containerImg {
  position: relative;
  text-align: center;
  color: white;
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  /* border: 1px solid white; */
  padding: 10%;
}

img {
  filter: saturate(1);
  /* opacity: 0.9; */
}

.buttonsthree {
  padding: 10px;
  background-color: black;
  border-radius: 50px;
  width: 150px;
  color: white;
  border: none;
}



.cards-trailers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  max-width: 1000px;
  margin: 3rem auto;




}


/*  */



.cardDiv {
  display: flex;
  flex-flow: row;
  gap: 3rem;
  padding: 5rem;
  justify-content: center;
}





  .cards-home {
    display: flex;
    flex-flow: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2rem 0;
    padding: 3rem;
    justify-content: center;
    gap: 1rem;
}

.we-provide{
  margin-top: 1rem;
  text-align: center;
}




.textOverImage {
  position: relative;
  text-align: left;

  color: white;
}

.textOverImage h2 {
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.logo{
  height: 60px;
}


.centered {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.map-class {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: gainsboro;
  color: black;
  padding: 6rem;
}

.shadow a {
  text-decoration: none;
}


.gallery {
  padding: 3rem;
  background-color: black;
  border: 1px solid white;

}

.gallery h2 {
  text-align: center;
  color: white;

}


@media only screen and (max-width: 600px) {

  .headerDiv {
    display: flex;
    justify-content: center;
  }

  .imageDiv {
    display: none;
  }

  .essentialHeading{
    display: flex;
    flex-direction: column;
    height: auto;
    
  }
  .essentialDescription{
    display: flex;
    flex-direction: column;
  }
  .map-class {
    display: flex;
    flex-flow: column;
}
.cards-home{
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.home-heading{
  margin-left: 0;
}


}