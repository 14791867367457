@import url('https://fonts.googleapis.com/css?family=Indie+Flower');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

body{
	background-color: rgb(237, 237, 237);
}

#pricing{
	width: 989px;
	margin: 100px auto;
	font-family: 'Open Sans', Helvetica;
}

.price_card{
	width: 300px;
	background: rgb(255, 255, 255);
	display: inline-table;
	top: 0;
}
.price_card:not(:last-child){
	margin-right: 40px;
}
.header{
	color: rgb(255, 255, 255);
}
.alpha .header{
	background: rgb(245, 166, 35);
}
.bravo .header{
	background: rgb(246, 77, 77);
}
.charlie .header{
	background: rgb(48, 219, 181);
}
.price{
	width: 100%;
	font-size: 60px;
	font-weight: 300;
	display: block;
	text-align: center;
	padding: 30px 0 10px;
}
.name{
	width: 100%;
	font-size: 15px;
	font-weight: 700;
	display: block;
	text-align: center;
	padding: 0 0 30px;
}
.features{
	list-style: none;
	text-align: center;
	color: rgb(138, 138, 138);
	margin: 0;
	padding: 0;
}
.features li{
	margin: 0 35px;
	padding: 20px 15px;
	width: 200px;
}
.features li:not(:last-child){
	border: 1px solid rgb(242, 242, 242);
	border-top: 0;
	border-left: 0;
	border-right: 0;
}
.pricecard-button{
	color: rgb(255, 255, 255);
	border: 0;
	border-radius: 5px;
	height: 40px;
	width: 200px;
	display: block;
	font-weight: 700;
	font-size: 15px;
	text-transform: uppercase;
	margin: 20px auto 35px;
}
.alpha button{
	background: rgb(245, 166, 35);
}
.bravo button{
	background: rgb(246, 77, 77);
}
.charlie button{
	background: rgb(48, 219, 181);
}
.tip{
	font-family: 'Indie Flower';
	position: absolute;
	color: rgb(117, 117, 117);
	margin: 10px 5px;
}

.price_cards_div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1rem;
}